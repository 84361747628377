import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import DBResourceDetailComp from "components/resource/DBResourceDetailComp";
import JournalResourceDetailComp from "components/resource/JournalResourceDetailComp";
import SearchBlock from "components/search/SearchBlock";

import { autobind } from "react-decoration";
import { page } from "components/page";

@withI18next(["common"])
@page
class ResourceDetail extends React.Component {
  constructor(props) {
    super(props);
    let state = this.getQueryState(props);
    this.state = state;
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {
    if (this.props !== props) {
      this.setQueryState(props);
    }
  }

  @autobind
  setQueryState(props) {
    let state = this.getQueryState(props);

    this.setState({
      ...state,
    });
  }

  @autobind
  getQueryState(props) {
    let { search, readerStore } = props;
    let params = { ...search };
    let { id, isprint, resourceType } = params;
    let state = {
      id: id,
      isprint: isprint,
      auth: readerStore.auth,
      resourceType: resourceType,
    };
    return state;
  }

  render() {
    let { readerStore, t } = this.props;
    let { auth } = readerStore;
    let { resourceType } = this.state;
    let journalType = false;
    if (
      resourceType === "journal" ||
      resourceType === "paper_journal" ||
      resourceType === "ebook"
    ) {
      journalType = true;
    }
    return (
      <Layout {...this.props}>
        <div className="main lp" id="center">
          <a class="accesskey" href="#aC" id="aC" accesskey="C" title="主要內容區">:::</a>
          <SearchBlock searchType="resource" />
          <section className="main_content erm">
            <div className="container">
              <div className="search_result">
                <h2>{t("jumperrwd.common.bookDetail")}</h2>
                <div className="detail_lightbox normalPage">
                  <div className="detailblock">
                    {resourceType === "databases" && (
                      <section>
                        <div className="container">
                          <DBResourceDetailComp
                            {...this.props}
                            {...this.state}
                            isPage={true}
                          />
                        </div>
                      </section>
                    )}
                    {journalType && (
                      <section>
                        <div className="container">
                          <JournalResourceDetailComp
                            {...this.props}
                            {...this.state}
                            scrolldown_fix={""}
                            displayFixed={"none"}
                            isPage={true}
                          />
                        </div>
                      </section>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default ResourceDetail;
